<template>
    <v-app>
        <v-main>
            <ToolbarTitle
            v-on:retroceder="$router.go(-1)"
            :title="'Creacion de busquedas de informacion'"

          >
          <template  slot="accion" >
              <v-btn @click="dialogo_crear_busqueda=true" dark color="white" style="color:blue">
                  Crear busqueda
                  <v-icon>mdi-magnify</v-icon>
              </v-btn>
          </template>
          </ToolbarTitle>
          <v-card class="mt-2">
                <v-card-title>
                Informacion a buscar
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                ></v-text-field>
                </v-card-title>
                <v-data-table
                :headers="headers"
                :items="desserts"
                :search="search"
                >
                    <template v-slot:item.filtros="{ item }">
                       <p v-for="(nombre,index2) in item.filtros" :key="index2">
                           {{nombre.name}}
                       </p>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn small rounded dark color="indigo" @click="revisar(item)"> Revisar <v-icon>mdi-eye</v-icon> </v-btn>
                    </template>
                </v-data-table>
            </v-card>
            

        </v-main>
        <v-dialog v-model="dialogo_crear_busqueda" max-width="700" persistent  >
            <v-toolbar dark color="indigo">
                <v-toolbar-title>Crear busqueda</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="dialogo_crear_busqueda=false" icon> <v-icon>mdi-close</v-icon> </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <br>
                    <v-form ref="formulario1" v-model="formulario1">
                        <v-text-field v-model="nombre_busqueda" label="Nombre de la busqueda" rounded filled dense ></v-text-field>
                        <v-btn block color="indigo" dark @click="filtros.push({nombre: ''})" >Agregar filtro</v-btn>
                        <v-row v-for="(filtro,index) in filtros" :key="index" >
                            <v-col cols="11">
                                <v-text-field 
                                    :rules="[(v) => !!v || 'Es requerido asignar un nombre al campo']"
                                    required v-model="filtro.nombre" label="Nombre del filtro" rounded filled dense >
                                </v-text-field>
                            </v-col>
                            <v-col cols="1">
                                <v-btn @click="filtros.length>1 ?  filtros.splice(index, 1) : 'No puedes'" icon color="red" > <v-icon>mdi-delete</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn :disabled="!formulario1" @click="crear_busqueda()" style="color:white" color="indigo" block>Crear busqueda</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogo_ingresar_datos_busqueda" fullscreen>
            <v-toolbar dark color="indigo">
                <v-toolbar-title>Crear busqueda de: "{{busqueda.nombre}}"" </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="dialogo_ingresar_datos_busqueda=false" icon> <v-icon>mdi-close</v-icon> </v-btn>
            </v-toolbar>
            <v-card>
                <v-row class="mx-5">
                    <v-col cols="12" sm="3">
                        <v-toolbar dark color="indigo darken-4">
                            <v-toolbar-title>
                                Ingresar datos
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn v-if="editar" icon @click="editar=false;reset()" > <v-icon>mdi-close</v-icon> </v-btn>
                        </v-toolbar>
                        <v-card elevation="4">
                            <v-card-text>
                                <v-form ref="formulario2" v-model="formulario2">
                                    <v-row v-for="(item,index) in fields" :key="index">
                                        <v-text-field :rules="[(v) => !!v || 'Es requerido asignar un valor al campo']"
                                            required  filled rounded dense v-model="item.value" :label="item.label">
                                        </v-text-field>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn v-if="!editar" @click="agregar_dato_busqueda(fields)" block color="indigo" style="color:white" :disabled="!formulario2"> Crear </v-btn>
                                <v-btn v-else @click="editar_datos_busqueda_endpoint(fields)" block color="green" style="color:white" :disabled="!formulario2"> <v-icon>mdi-pencil</v-icon> Editar  </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="9">
                        <v-toolbar dark color="indigo darken-4">
                            <v-toolbar-title> Informacion de: "{{busqueda.nombre}}"</v-toolbar-title>
                        </v-toolbar>
                        <v-card elevation="4">
                            <v-card-title>
                               
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar"
                                single-line
                                hide-details
                            ></v-text-field>
                            </v-card-title>
                            <v-data-table
                            :headers="headers2"
                            :items="desserts2"
                            :search="search2"
                            >
                                <template v-slot:item.actions="{ item }">
                                    <v-btn icon color="green" @click="editar_dato_busqueda(item)"> <v-icon>mdi-pencil</v-icon> </v-btn>
                                    <v-btn @click="eliminar_dato_busqueda(item)" icon color="red"> <v-icon>mdi-delete</v-icon></v-btn>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </v-app>
</template>
<script>
import ToolbarTitle from "../../components/admin/ToolbarTitle";
//import TablaUsuarios from "../../components/admin/TablaUsuarios";
export default {
    components: {
        ToolbarTitle
    },
    data(){
        return {
                formulario1: true,
               
                filtros: [
                    {nombre: ''}
                ],
                nombre_busqueda: '',
                dialogo_crear_busqueda: false,
               
                search: '',
                headers: [
                        {text: 'Nombre busqueda',value: 'nombre'},
                        { text: 'Filtros', value: 'filtros', sortable:false },
                        { text: 'Revisar', value: 'actions', sortable: false, },
                    ],
            desserts: [],
            busqueda: {
                nombre: '',
            },
           
             
            dialogo_ingresar_datos_busqueda: false,
            formulario2: true,
            fields: [],

            headers2: [],
            desserts2: [],
            search2: '',
            dato: {},

            editar: false,
        }
    },
   async created(){
       console.log('s')
        try {
            let response = await this.$store.dispatch('llamado_get',
            {url: 'https://siett.educandote.co/parse/classes/prueba01Busquedas',tipo_header:'parse'})
            console.log(response)
            this.desserts = [...response.data.results]
        } catch (error) {
            console.log(error)
        }
    },
    methods: {
       async revisar(busqueda){
            console.log(busqueda)
            this.busqueda = busqueda
            this.headers2 = []
            this.fields = []
            for (let index = 0; index < busqueda.filtros.length; index++) {
                 this.fields.push({
                     label: busqueda.filtros[index].name,
                     value: '',
                 })
                 this.headers2.push({
                     text: busqueda.filtros[index].name,
                     value: busqueda.filtros[index].name
                 })
            }
            console.log(this.fields)
            this.dialogo_ingresar_datos_busqueda = true
            this.headers2.push({text: 'Acciones', value: 'actions', sortable: false, })
            try {
                let response = await this.$store.dispatch('llamado_get',
                {url: 'https://siett.educandote.co/parse/classes/'+busqueda.nombre_endpoint,tipo_header:'parse'})
                console.log(response)
                this.desserts2 = [...response.data.results]
            } catch (error) {
                console.log(error)
            }
           
        },
        async agregar_dato_busqueda(dato){
            console.log(dato)
            let body = await this.concatenarDatos(dato)

            try {
                let response = await this.$store.dispatch('llamado_post',
                {url: 'https://siett.educandote.co/parse/classes/'+this.busqueda.nombre_endpoint,
                    body: body,
                    tipo_header: 'parse'
                })
                  
                  body.objectId = response.data.objectId
                  this.desserts2.unshift(body)
                  this.reset()
                  console.log(response)
            } catch (error) {
                console.log(error)
            }
        },
        eliminar_dato_busqueda(dato){
            console.log(dato)
        },
        editar_dato_busqueda(dato){
           this.dato = dato 
            Object.keys(dato).forEach( (key) => {
                console.log(key)
                if(key!='createdAt' && key!='updatedAt' && key!= 'objectId' ){

                    for (let index = 0; index < this.fields.length; index++){
                        if(this.fields[index].label==key){
                            this.fields[index].value = dato[key]
                        }
                    }
                }
            })
            this.editar = true
        },
       async editar_datos_busqueda_endpoint(dato){
           console.log(this.desserts2)
           console.log(dato)
            let body = await this.concatenarDatos(dato)
            console.log(body)
            try {
                let response = await this.$store.dispatch('llamado_put',
                {url: 'https://siett.educandote.co/parse/classes/'+this.busqueda.nombre_endpoint+'/'+this.dato.objectId,
                    body: body,
                    tipo_header: 'parse'
                })
                console.log(response)
                body.objectId = this.dato.objectId
               let index = this.desserts2.indexOf(this.dato)
               console.log(index)
               this.desserts2.splice(index,1)
               this.desserts2.unshift(body)
               
               this.editar = false
             
               this.reset()
            } catch (error) {
                console.log(error)
            }
            
        },
        concatenarDatos(dato){
            let obj = ""
            for (let index = 0; index < dato.length; index++) {
                
                obj =obj+`"${dato[index].label}":"${dato[index].value}",`;
            }
            console.log(obj)
            let cadena = obj;
            let cadenaCorregida = cadena.substring(0, cadena.length - 1);
            console.log(cadenaCorregida);
            cadenaCorregida = "{"+cadenaCorregida+"}"
            console.log(cadenaCorregida)
            cadenaCorregida = JSON.parse(cadenaCorregida)
            return cadenaCorregida
        },
        reset(){
             for (let index = 0; index < this.fields.length; index++) {
                    this.fields[index].value = ''   
                }
            this.$refs.formulario2.resetValidation()
        },
       async crear_busqueda(){
            let fields = [];
            console.log(fields);
            var str;
            var res;
            for (let index = 0; index < this.filtros.length; index++) {
                console.log(index);
                str = this.filtros[index].nombre;
                res = str.replace(/ /g, "_");

                console.log(res);
                fields.push({ name: res, type: "string" });
            }
            let name = this.nombre_busqueda.replace(/ /g, "");
            console.log(name);
            console.log(fields);

            //Endpoint nombre de busqueda
            let body =  {
                        "nombre": this.nombre_busqueda,
                        "nombre_endpoint": name,
                        "filtros": fields
                    }
            try {
                let response = await this.$store.dispatch('llamado_post',
                {url: 'https://siett.educandote.co/parse/classes/prueba01Busquedas',
                    body:body,
                    tipo_header: 'parse'
                })
                 console.log(response)
                 body.objectId = response.data.objectId
                 this.desserts.unshift(body)
                 for (let index = 0; index < this.filtros.length; index++) {
                     this.filtros[index].nombre = ''
                     
                 }
                 console.log(this.desserts)
                 this.dialogo_crear_busqueda = false

                 this.$store.commit('exitoso','Ls busqueda de: '+this.nombre_busqueda+' Ha sido creada exitosamente')
                 this.nombre_busqueda = ''
                 this.$refs.formulario1.resetValidation()
            } catch (error) {
                console.log(error)
            }
            
        }
    }
}
</script>